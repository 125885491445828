import Axios, { AxiosInstance } from 'axios';
import { setAxiosInterceptors } from './axiosInterceptors';

export function initAxiosFor(baseUrl: string) {
  const axiosInstance = Axios.create();

  axiosInstance.defaults.baseURL = baseUrl;

  setAxiosInterceptors(axiosInstance);

  return axiosInstance;
}

export function configureAxiosInstance(axiosInstance: AxiosInstance) {
  setAxiosInterceptors(axiosInstance);

  return axiosInstance;
}