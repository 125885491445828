import { Route } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import AccountsPage from "../accounts/AccountsPage";
import EditAdAccountPage from "../accounts/EditAdAccountPage";
import SitesPage from "../sites/SitesPage";
import EditSitePage from "../sites/EditSitePage";
import TenantsPage from "../tenants/TenantsPage";
import EditTenantPage from "../tenants/EditTenantPage";
import { MaintenanceHandler } from "@bitmesh/maintenance";
import { useAppContext } from "./AppContext";
import { FILE_API_URL } from "../environment";
import ProfilesPage from "../profiles/ProfilesPage";
import EditProfilePage from "../profiles/EditProfilePage";
import EditPhoneAccountPage from "../accounts/EditPhoneAccountPage";
import { getToken } from "../security/token";

export const AppRoutes = () => {
  const { user } = useAppContext();
  return (
    <>
      {user && 
        <MaintenanceHandler 
          fileApiUrl={FILE_API_URL}
          userId={user?.id ?? ''}
          appId="bitmesh.admin.app"
          tokenProvider={async (url: string) => new Promise(r => r(getToken() ?? ""))}
        />}
      <Route exact path="/" render={(props) => <Dashboard {...props} />} />

      <Route exact path="/accounts" render={(props) => <AccountsPage {...props} />} />
      <Route exact path="/edit-ad-account/:accountId" render={(props) => <EditAdAccountPage {...props} />} />
      <Route exact path="/edit-ad-account" render={(props) => <EditAdAccountPage {...props} />} />
      <Route exact path="/edit-phone-account/:accountId" render={(props) => <EditPhoneAccountPage {...props} />} />
      <Route exact path="/edit-phone-account" render={(props) => <EditPhoneAccountPage {...props} />} />

      <Route exact path="/profiles" render={(props) => <ProfilesPage {...props} />} />
      <Route exact path="/edit-profile/:profileId" render={(props) => <EditProfilePage {...props} />} />
      <Route exact path="/edit-profile" render={(props) => <EditProfilePage {...props} />} />

      <Route exact path="/sites" render={(props) => <SitesPage {...props} />} />
      <Route exact path="/edit-site/:siteId" render={(props) => <EditSitePage {...props} />} />
      <Route exact path="/edit-site" render={(props) => <EditSitePage {...props} />} />
      
      <Route exact path="/tenants" render={(props) => <TenantsPage {...props} />} />
      <Route exact path="/edit-tenant/:tenantId" render={(props) => <EditTenantPage {...props} />} />
      <Route exact path="/edit-tenant" render={(props) => <EditTenantPage {...props} />} />
    </>
  );
};
