import { Typography } from "@mui/material";
import { Route } from "react-router-dom";

export const PageTitle = () => {
  return (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      sx={{ flexGrow: 1 }}
    >
      <Route exact path="/" render={() => <>Dashboard</>} />

      <Route exact path="/accounts" render={() => <>Accounts</>} />
      <Route exact path="/edit-ad-account/:accountId" render={() => <>Edit AD Account</>} />
      <Route exact path="/edit-ad-account" render={() => <>Add new AD Account</>} />
      <Route exact path="/edit-phone-account/:accountId" render={() => <>Edit Phone Account</>} />
      <Route exact path="/edit-phone-account" render={() => <>Add new Phone Account</>} />

      <Route exact path="/profiles" render={() => <>Profiles</>} />
      <Route exact path="/edit-profile/:profileId" render={() => <>Edit Profile</>} />
      <Route exact path="/edit-profile" render={() => <>Add new Profile</>} />

      <Route exact path="/sites" render={() => <>Sites</>} />
      <Route exact path="/edit-site" render={() => <>Add new Site</>} />
      <Route exact path="/edit-site/:siteId" render={() => <>Edit Site</>} />

      <Route exact path="/tenants" render={() => <>Tenants</>} />
      <Route exact path="/edit-tenant" render={() => <>Add new Tenant</>} />
      <Route exact path="/edit-tenant/:tenantId" render={() => <>Edit Tenant</>} />
    </Typography>
  );
};
