import {
  Container
} from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useAppContext } from '../core/AppContext';

export default function Dashboard(props: RouteComponentProps) {
  const app = useAppContext();
  return (
    <div className="pt-3 pl-2">
      <Container fluid>
        {app.isAuthenticated &&
          <div>
            <h2>Welcome {app.user?.name || ''}!</h2>
          </div>
        }
      </Container>
    </div>
  );
}
