import React, { useState } from "react";
import { Tooltip } from '@mui/material';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

type Props = {
  text: string;
}

export const CopyIcon: React.FC<Props> = ({ text }) => {
  const [copiedText, setCopiedText] = useState("");

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => setCopiedText(text)}
    >
      <Tooltip
        title={
          copiedText === text
            ? "Copied!"
            : "Copy To Clipboard"
        }
        placement="top"
      >
        {
          copiedText === text
            ? <AssignmentTurnedInIcon sx={{ cursor: 'pointer' }} />
            : <ContentCopyIcon sx={{ cursor: 'pointer' }} />
        }
      </Tooltip>
    </CopyToClipboard>
  );
};