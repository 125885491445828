/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, CircularProgress, Box, Snackbar, Alert, Paper } from "@mui/material";

import * as Yup from "yup";
import { initUserAccountService, PhoneUserAccount } from "../security/userAccountService";
import { SearchableList } from "../shared/SearchableList";
import { initUserProfileService, UserProfile } from "../security/userProfileService";

type Props = {
  account: PhoneUserAccount | null;
};

export const EditPhoneAccountForm: FC<Props> = ({ account }) => {
  const accountService = initUserAccountService();
  const profileService = initUserProfileService();

  const [profiles, setProfiles] = useState<UserProfile[]>([]);

  const [selectedProfile, setSelectedProfile] = useState<string|null>(account?.userProfileId ?? null);

  const [profileErrorMessage, setProfileErrorMessage] = useState<string>("");

  const [successOpen, setSuccessOpen] = useState<boolean>(false);

  useEffect(() => {
    profileService.getUserProfiles().then(setProfiles);
  }, []);

  useEffect(() => {
    if (!selectedProfile) {
      setProfileErrorMessage("Please select a profile");
    } else {
      setProfileErrorMessage("");
    }
  }, [selectedProfile]);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Phone number is required")
      // .matches(
      //   // Phone Number validation regex
      //   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      //   "Must be a valid phone number."
      // ),
  });
  const formOptions = {
    defaultValues: {
      phoneNumber: account?.phoneNumber ?? "",
    },
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  async function onSubmit({ phoneNumber }: any) {
    if (!selectedProfile) {
      setProfileErrorMessage("Please select a profile");
      return;
    }
    if (!account) {
      await accountService.addUserAccount({
        phoneNumber: phoneNumber as string,
        type: "PhoneUserAccount",
        externalUserId: phoneNumber,
        userProfileId: selectedProfile,
      });
    } else {
      await accountService.updateUserAccount({
        id: account.id,
        phoneNumber: phoneNumber as string,
        type: "PhoneUserAccount",
        externalUserId: phoneNumber,
        userProfileId: selectedProfile,
      });
    }
    setSuccessOpen(true);
  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: "1em" },
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          type="text"
          {...register("phoneNumber")}
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.phoneNumber?.message}
        </div>
      </div>

      <div style={{ paddingLeft: "1em" }}>
        <Paper sx={{
          marginBottom: "1em",
          padding: '1em',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <h5>Selected Profile</h5> <h5>{profiles.find(p => p.id === selectedProfile)?.name ?? ""}</h5>
        </Paper>
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {profileErrorMessage}
        </div>
      </div>
      <div style={{ paddingLeft: "1em" }}>
        <SearchableList
          elementsContainerStyle={{
            maxHeight: '250px',
            overflowY: 'scroll',
          }}
          list={profiles}
          renderElement={(p: UserProfile) => {
            return (
              <Paper key={p.id} sx={{
                marginBottom: "1em",
                padding: '1em',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <h4>{p.name}</h4>
                <button
                  disabled={p.id === selectedProfile}
                  className="btn btn-sm btn-primary"
                  type="button"
                  onClick={() => setSelectedProfile(p.id)}
                >
                  Select
                </button>
              </Paper>
            );
          }}
        />
      </div>
      <button disabled={isSubmitting} className="btn btn-primary" type="submit">
        {isSubmitting ? <CircularProgress style={{ color: "#fff" }} /> : "Save"}
      </button>
      {errors.apiError && <div>{errors.apiError?.message}</div>}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          Phone Account successfully {!account ? 'created' : 'updated'}!
        </Alert>
      </Snackbar>
    </Box>
  );
};
