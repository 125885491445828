/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, CircularProgress, Box, Snackbar, Alert, FormControlLabel, FormGroup, Checkbox, Paper } from "@mui/material";

import { useAppContext } from "../core/AppContext";
import * as Yup from "yup";
import { useAdAccountService } from "./adAccountService";
import { useSiteService } from "../sites/siteService";
import { useTenantService } from "../tenants/tenantService";
import MultiselectOption from "../shared/MulitselectOption";
import { AppRoleAssignment, User } from "microsoft-graph";
import { convertListToString, convertStringToList } from "./utils";
import { AllowedDomains, AppName, AppNames, AppServicePrincipals, BITMESH_TENANT } from "../core/AppConfig";
import { initUserProfileService, UserProfile } from "../security/userProfileService";
import { SearchableList } from "../shared/SearchableList";
import { initUserAccountService } from "../security/userAccountService";

type Props = {
  adAccount: User | null;
  adAccountRoleAssignments: AppRoleAssignment[] | null;
};

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const EditAdAccountForm: FC<Props> = ({ adAccount, adAccountRoleAssignments }) => {
  const app = useAppContext();
  const adAccountService = useAdAccountService();
  const profileService = initUserProfileService();
  const accountService = initUserAccountService();
  const siteService = useSiteService();
  const tenantService = useTenantService();

  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  
  const [tenants, setTenants] = useState<any[]>([]);
  const [sites, setSites] = useState<any[]>([]);
  const [profiles, setProfiles] = useState<UserProfile[]>([]);

  const [siteOptions, setSiteOptions] = useState<any[]>([]);
  const [selectedSites, setSelectedSites] = useState<any[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<any[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<string|null>(null);
  const [selectedApplications, setSelectedApplications] = useState<any[]>([]);
  const [providedApplications, setProvidedApplications] = useState<any[]>([]);

  useEffect(() => {
    tenantService.getAll().then(setTenants);
    siteService.getAll().then(sites => {
      setSites(sites);
      setSiteOptions(sites);
    });
    profileService.getUserProfiles().then(setProfiles);
  }, [app.user]);

  const tenantsDefault =
    convertStringToList(
      adAccount?.onPremisesExtensionAttributes?.extensionAttribute1 as string
    ) ?? [];
  const sitesDefault =
    convertStringToList(
      adAccount?.onPremisesExtensionAttributes?.extensionAttribute2 as string
    ) ?? [];

  useEffect(() => {
    if (adAccount) {
      setSelectedTenants(tenantsDefault);
      setSelectedSites(sitesDefault);
      adAccount?.id && accountService.getUserFor(adAccount?.id)
        .then(account => setSelectedProfile(account.userProfileId));
    }
  }, [adAccount]);

  useEffect(() => {
    if (adAccountRoleAssignments) {
      const existingApplications = adAccountRoleAssignments.map(userRoleAssignment => {
        return AppNames.find((appName: AppName) => {
          const { servicePrincipalId, appRoleId } = AppServicePrincipals[appName];
          return servicePrincipalId === userRoleAssignment.resourceId 
            && (appRoleId === userRoleAssignment.appRoleId || userRoleAssignment.appRoleId === EMPTY_GUID);
        });
      });
      setSelectedApplications(existingApplications);
      setProvidedApplications(existingApplications);
    }
  }, [adAccountRoleAssignments]);

  const validationSchema = Yup.object().shape({
    userPrincipalName: Yup.string().required("Username is required")
      .test('test-domain', 'The provided domain is not supported',
      value => AllowedDomains.some(domain => value?.endsWith(domain))),
    displayName: Yup.string().required("Display Name is required"),
    ...(!adAccount
      ? { 
        password: Yup.string().required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      }
      : {}),
    applications: Yup.string().required("Application is required"),
    tenants: Yup.string().required("Tenant is required"),
    sites: Yup.string().required("Sites is required"),
  });
  const formOptions = {
    defaultValues: {
      displayName: adAccount?.displayName ?? "",
      userPrincipalName: adAccount?.userPrincipalName ?? "",
      forceChangePassword: true,
      tenants: tenantsDefault.join(","),
      sites: sitesDefault.join(","),
    },
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, setValue, clearErrors } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    clearErrors('applications');
    setValue("applications", `${selectedApplications.join(",")}`);
  }, [selectedApplications]);

  useEffect(() => {
    clearErrors('tenants');
    setValue("tenants", `${selectedTenants.join(",")}`);
    setSiteOptions(sites.filter(site => selectedTenants.includes(BITMESH_TENANT) || selectedTenants.includes(site.tenantId)));
  }, [selectedTenants]);

  useEffect(() => {
    clearErrors('sites');
    setValue("sites", `${selectedSites.join(",")}`);
  }, [selectedSites]);

  async function onSubmit({ userPrincipalName, password, displayName, forcePasswordChange }: any) {
    if (!adAccount) {
      await adAccountService.addAdAccount(
        userPrincipalName,
        displayName as string,
        password,
        convertListToString(selectedTenants),
        convertListToString(selectedSites),
        selectedApplications,
        forcePasswordChange,
        selectedProfile || null
      );
    } else {
      const newApplications = selectedApplications
        .filter(app => !providedApplications.includes(app));
      const removedAppRoleAssignments = providedApplications
        .filter(app => !selectedApplications.includes(app))
        .map((app: AppName) => {
          return adAccountRoleAssignments?.find(userRoleAssignment => {
            const { servicePrincipalId, appRoleId } = AppServicePrincipals[app];
            return servicePrincipalId === userRoleAssignment.resourceId && appRoleId === userRoleAssignment.appRoleId;
          }) as AppRoleAssignment;
        });
      await adAccountService.updateAdAccount(
        adAccount?.id as string,
        userPrincipalName as string,
        displayName as string,
        convertListToString(selectedTenants),
        convertListToString(selectedSites),
        newApplications,
        removedAppRoleAssignments,
        forcePasswordChange,
        selectedProfile || null
      );
    }
    setSuccessOpen(true);
  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: "1em" },
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <TextField
          label="Display Name"
          variant="outlined"
          fullWidth
          type="text"
          {...register("displayName")}
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.displayName?.message}
        </div>
      </div>
      <div>
        <TextField
          label="User Principal Name"
          variant="outlined"
          fullWidth
          type="text"
          {...register("userPrincipalName")}
          disabled={!!adAccount}
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.userPrincipalName?.message}
        </div>
      </div>
      {!adAccount && (
        <div>
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            {...register("password")}
          />
          <div style={{ paddingLeft: "1em" }}>
            <FormGroup>
              <FormControlLabel 
                control={<Checkbox defaultChecked={formOptions.defaultValues.forceChangePassword} {...register('forcePasswordChange')} />}
                label="Force Password Change on First Login"
              />
            </FormGroup>
          </div>
          <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
            {errors.password?.message}
          </div>
        </div>
      )}
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Applications"
          values={selectedApplications}
          onChange={setSelectedApplications}
          items={AppNames.map((app) => ({ label: app, value: app }))}
          selectAllLabel={`All Applications`}
          renderSelectedValues={(values: any) =>
            values.length === AppNames.length
              ? `All Applications`
              : values
                  .map((value: any) => value)
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.applications?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Tenants"
          values={selectedTenants}
          onChange={setSelectedTenants}
          items={tenants.map((tenant) => ({ label: tenant.name, value: tenant.id }))}
          selectAllLabel={`All Tenants`}
          renderSelectedValues={(values: any) =>
            values.length === tenants.length
              ? `All Tenants`
              : values
                  .map(
                    (value: any) =>
                      tenants[tenants.findIndex((tenant) => tenant.id === value)]
                        ?.name
                  )
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.tenants?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Sites"
          values={selectedSites}
          onChange={setSelectedSites}
          items={siteOptions.map((site) => ({ label: site.name, value: site.id }))}
          selectAllLabel={`All Sites`}
          renderSelectedValues={(values: any) =>
            values.length === siteOptions.length
              ? `All Sites`
              : values
                  .map(
                    (value: any) =>
                      sites[sites.findIndex((site) => site.id === value)]?.name
                  )
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.sites?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em" }}>
        <Paper sx={{
          marginBottom: "1em",
          padding: '1em',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <h5>Selected Profile</h5> <h5>{profiles.find(p => p.id === selectedProfile)?.name ?? ""}</h5>
        </Paper>
      </div>
      <div style={{ paddingLeft: "1em" }}>
        <SearchableList
          elementsContainerStyle={{
            maxHeight: '250px',
            overflowY: 'scroll',
          }}
          list={profiles}
          renderElement={(p: UserProfile) => {
            return (
              <Paper key={p.id} sx={{
                marginBottom: "1em",
                padding: '1em',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <h4>{p.name}</h4>
                <button
                  disabled={p.id === selectedProfile}
                  className="btn btn-sm btn-primary"
                  type="button"
                  onClick={() => setSelectedProfile(p.id)}
                >
                  Select
                </button>
              </Paper>
            );
          }}
        />
      </div>
      <button disabled={isSubmitting} className="btn btn-primary" type="submit">
        {isSubmitting ? <CircularProgress style={{ color: "#fff" }} /> : "Save"}
      </button>
      {errors.apiError && <div>{errors.apiError?.message}</div>}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          User successfully {adAccount ? 'updated' : 'created'}!
        </Alert>
      </Snackbar>
    </Box>
  );
};
