import { Paper, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { UserAccount } from '../security/userAccountService';

type Props = {
  account: UserAccount;
}

const EditAccountRootPaths = {
  UsernameUserAccount: (account: UserAccount) => `/edit-ad-account/${account.externalUserId}`,
  PhoneUserAccount: (account: UserAccount) => `/edit-phone-account/${account.id}`,
}

export const AccountCard: React.FC<Props> = ({ account }) => {
  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h6"> <AssignmentIndIcon /> {account.externalUserId} </Typography>
          {'phoneNumber' in account && <><br /><LocalPhoneIcon /> {account.phoneNumber}</>}
          {'username' in account && <><br /><AlternateEmailIcon /> {account.username}</>}
        </div>
        <div>
          <Link to={`${EditAccountRootPaths[account.type](account)}`}>
            <EditIcon />
          </Link>
        </div>
      </div>
    </Paper>
  );
}