import { AppRoleAssignment, User } from "microsoft-graph";
import { AppName, AppNames, AppRoleValues, AppServicePrincipals } from "../core/AppConfig";
import { AddUserProfile, initUserProfileService } from "../security/userProfileService";
import { AddUsernameUserAccount, initUserAccountService } from "../security/userAccountService";
import { convertStringToList } from "./utils";

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export async function patch(users: User[], fetchUserRoleAssignments: (userId: string) => Promise<AppRoleAssignment[]>) {
  const accessInfoService = initUserProfileService();
  const userService = initUserAccountService();

  const allResults = await Promise.all(users.map(async (u) => {
    if(!u.id) return null;

    const user: AddUsernameUserAccount = {
      type: "UsernameUserAccount",
      userProfileId: "",
      externalUserId: u.id ?? "",
      username: u.userPrincipalName ?? ""
    };
    try {
      const userProfile = createUserProfileObject(u, await fetchUserRoleAssignments(u.id));

      const createdUserProfile = await accessInfoService.addOrUpdateUserProfileFor(u.id, userProfile);
      const createdUser = await userService.addUserAccount({...user, userProfileId: createdUserProfile.id});

      return { userProfile: createdUserProfile, userAccount: createdUser };
    } catch {
      return null;
    }
  }).filter(v => !!v));
  console.log(allResults);
}

export function createUserProfileObject(user: User, userRoleAssignments: AppRoleAssignment[]): AddUserProfile {
  const roles: any = userRoleAssignments?.map(userRoleAssignment => {
    return AppNames.find((appName: AppName) => {
      const { servicePrincipalId, appRoleId } = AppServicePrincipals[appName];
      return servicePrincipalId === userRoleAssignment.resourceId 
        && (appRoleId === userRoleAssignment.appRoleId || userRoleAssignment.appRoleId === EMPTY_GUID);
    });
  }).filter(appName => !!appName).map(appName => appName && AppRoleValues[appName]).filter(v => !!v) ?? [];

  const tenantIds =
    convertStringToList(
      user?.onPremisesExtensionAttributes?.extensionAttribute1 as string
    ) ?? [];
  const siteIds =
    convertStringToList(
      user?.onPremisesExtensionAttributes?.extensionAttribute2 as string
    ) ?? [];

  return {
    id: user.id,
    name: user.displayName ?? "",
    roles,
    tenantIds,
    siteIds
  } as any
}