import { Button } from "@mui/material";
import { Link, RouteComponentProps } from "react-router-dom";
import { TenantsList } from "./TenantsList"

export default function TenantsPage(props: RouteComponentProps) {
  return (
    <>
      <Link style={{ textDecoration: 'none', marginBottom: '1em' }} to="/edit-Tenant">
        <Button variant="contained">New Tenant</Button>
      </Link>
      <TenantsList />
    </>
  );
}