import { FC, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getAccessByCode, requestCodeGeneration } from "./getAccess";

// TODO: Handle failures

type Props = {
  open: boolean;
  onSuccess?: () => any;
  onClose?: () => any;
};

export const PhoneSignDialog: FC<Props> = ({
  open,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");

  const [hasSentRequest, setHasSentRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSend = () => {
    if (hasSentRequest) {
      getAccessByCode(phoneNumber, code)
        .then(() => {
          onSuccess();
          setErrorMessage("");
        })
        .catch(() => setErrorMessage("Something went wrong, please check the code you have entered."));
    } else {
      requestCodeGeneration(phoneNumber)
        .then(() => {
          setHasSentRequest(true);
          setErrorMessage("");
        })
        .catch(() => setErrorMessage("Something went wrong, please check the number you have entered."));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Sign In</Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {hasSentRequest ? (
          <TextField
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        ) : (
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        )}
        <br/>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSend}>Send</Button>
      </DialogActions>
    </Dialog>
  );
};
