import { Box, CircularProgress, FormControlLabel, Switch, TextField } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTenantService } from "./tenantService";
import { useHistory } from "react-router-dom";
import { useState } from "react";

type Props = {
  tenant: any;
};

export const EditTenantForm: React.FC<Props> = ({ tenant }) => {

  const isAdd = !tenant;

  const [includeTypes, setIncludeTypes] = useState<boolean>(true);

  const tenantService = useTenantService();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const formOptions = {
    defaultValues: {
      name: tenant?.name ?? "",
      description: tenant?.description ?? "",
      notes: tenant?.notes ?? "",
    },
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const onSubmit = async (values: any) => {
    if(isAdd) {
      await tenantService.post({...values, createdOn: new Date().toUTCString()}, includeTypes);
    } else {
      await tenantService.put(tenant.id, {...tenant, ...values});
    }
    history.push('/tenants')
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: "1rem" },
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <TextField
          label="Name"
          variant="outlined"
          type="text"
          fullWidth
          {...register("name")}
        />
        <div>{errors.name?.message}</div>
      </div>
      <div>
        <TextField
          label="Description"
          variant="outlined"
          type="text"
          fullWidth
          multiline
          rows={3}
          {...register("description")}
        />
      </div>
      <div>
        <TextField
          label="Notes"
          variant="outlined"
          type="text"
          fullWidth
          multiline
          rows={3}
          {...register("notes")}
        />
      </div>
      {isAdd && (
        <FormControlLabel
          control={
            <Switch
              checked={includeTypes}
              onChange={(_, v) => setIncludeTypes(v)}
              color="primary"
            />
          }
          label={"Include Entity Types"}
        />
      )}
      <button disabled={isSubmitting} className="btn btn-primary">
        {isSubmitting ? <CircularProgress style={{ color: "#fff" }} /> : "Save"}
      </button>
    </Box>
  );
};
