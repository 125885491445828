import { Box, CircularProgress, FormControlLabel, Grid, MenuItem, Paper, Select, Switch, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSiteService } from "./siteService";
import { useHistory } from "react-router-dom";
import { useTenantService } from "../tenants/tenantService";
import { useEffect, useState } from "react";

import CronDefinitionField from "./cron-definition/CronDefinitionField";
import { UpcomingTimes } from "./cron-definition/UpcomingTimes";
import SimpleCronDefinitionField, { isCronGeneratedInSimpleUi } from "./cron-definition/SimpleCronDefinitionField";
import { getNextDatesFromCronStatement } from "./cron-definition/util";

type Props = {
  site: any;
};

// Note: this is not used! In order to use it just add .cronStatement("Message") on Yup.string()
Yup.addMethod(Yup.string, "cronStatement", function (errorMessage) {
  return this.test(`test-cron-statement`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      !!getNextDatesFromCronStatement(value ?? '')?.length || createError({ path, message: errorMessage })
    );
  });
});

export const EditSiteForm: React.FC<Props> = ({ site }) => {

  const siteService = useSiteService();
  const history = useHistory();

  const tenantService = useTenantService();

  const [tenants, setTenants] = useState<any>(null);

  useEffect(() => {
    tenantService.getAll().then(setTenants);
  }, []);

  const [todoFromCron, setTodoFromCron] = useState(site?.reportCron ?? '');
  const [todoFromCronSimple, setTodoFromCronSimple] = useState(site?.reportCron ?? '');
  const [isAdvanced, setAdvanced] = useState(!isCronGeneratedInSimpleUi(site?.reportCron));
  const CronDefinitionComponent = isAdvanced ? CronDefinitionField : SimpleCronDefinitionField;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    siteType: Yup.string().required("Site type is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    tenantId: Yup.string().required("Tenant is required"),
    // @ts-ignore
    reportCron: Yup.string().required('Report Cron statement is required'),
  });
  const formOptions = {
    defaultValues: {
      name: site?.name ?? "",
      siteType: site?.siteType ?? "",
      tenantId: site?.tenantId ?? "",
      phoneNumber: site?.phoneNumber ?? "",
      address: site?.address ?? "",
      reportCron: site?.reportCron ?? '',
    },
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, setError, formState, setValue } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const cronStatementEffect = (cronStatement: string) => () => {
    setValue('reportCron', cronStatement);
  }
  useEffect(cronStatementEffect(todoFromCron), [todoFromCron]);
  useEffect(cronStatementEffect(todoFromCronSimple), [todoFromCronSimple]);

  const onSubmit = async (values: any) => {
    if(!site) {
      await siteService.post({...values, createdOn: new Date().toUTCString()});
    } else {
      await siteService.put(site.id, {...site, ...values});
    }
    history.push('/sites')
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: "1rem" },
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <TextField
          label="Name"
          variant="outlined"
          type="text"
          fullWidth
          {...register("name")}
        />
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.name?.message}</div>
      </div>
      <div>
        <TextField
          {...register("siteType")}
          fullWidth
          select
          onChange={(e) => setValue("siteType", e.target.value)}
          label={"Site Type"}
          defaultValue={formOptions.defaultValues.siteType}
        >
          <MenuItem value={"Restaurant"}>Restaurant</MenuItem>
          <MenuItem value={"AmusementPark"}>Amusement Park</MenuItem>
          <MenuItem value={"Building"}>Building</MenuItem>
        </TextField>
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.siteType?.message}</div>
      </div>
      <div>
        <TextField
          {...register("tenantId")}
          fullWidth
          select
          onChange={(e) => setValue("tenantId", e.target.value)}
          label={"Tenant"}
          defaultValue={formOptions.defaultValues.tenantId}
        >
          {tenants?.map((tenant: any) => <MenuItem value={tenant.id} key={tenant.id}>{tenant.name}</MenuItem>) ?? []}
        </TextField>
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.tenantId?.message}</div>
      </div>
      <div>
        <TextField
          label="Phone Number"
          variant="outlined"
          type="text"
          fullWidth
          {...register("phoneNumber")}
        />
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.phoneNumber?.message}</div>
      </div>
      <div>
        <TextField
          label="Report Cron Statement"
          variant="outlined"
          type="text"
          fullWidth
          {...register("reportCron")}
        />
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.reportCron?.message}</div>
      </div>
      <div>
        <Paper style={{padding: '1em', margin: '1em'}}>
          <Grid item style={{ display: "flex", justifyContent: 'space-between' }}>
            <Typography variant="h6">Report Cron Statement</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isAdvanced}
                  onChange={(_, v) => setAdvanced(v)}
                  color="primary"
                />
              }
              label={"Advanced"}
            />
          </Grid>
          <CronDefinitionComponent
            prefix={`Report generation starts on `}
            value={isAdvanced ? todoFromCron : todoFromCronSimple}
            onChange={isAdvanced ? setTodoFromCron : setTodoFromCronSimple}
            suffix={<UpcomingTimes cronStatement={isAdvanced ? todoFromCron : todoFromCronSimple} />}
          />
        </Paper>
      </div>
      <div>
        <TextField
          label="Address"
          variant="outlined"
          type="text"
          fullWidth
          {...register("address")}
        />
        <div style={{ color: 'red', fontSize: '0.8em', paddingLeft: '1em' }}>{errors.address?.message}</div>
      </div>
      <button disabled={isSubmitting} className="btn btn-primary">
        {isSubmitting ? <CircularProgress style={{ color: "#fff" }} /> : "Save"}
      </button>
    </Box>
  );
};
