import { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectItem } from "./SelectOption";
import { FormControl, InputLabel } from "@mui/material";

type RenderValuesFunction = (values: string[] | number[]) => string;

type Props = {
  items: SelectItem[];
  values?: string[] | number[];
  onChange?: any;
  renderSelectedValues?: RenderValuesFunction;
  selectAllLabel?: string;
  fullWidth?: boolean;
  label?: string;
  variant?: "standard" | "outlined" | "filled";
};

const MultiselectOption: FC<Props> = ({
  items,
  values,
  onChange,
  renderSelectedValues = (values: string[] | number[]) => values.join(", "),
  selectAllLabel = "Select All",
  fullWidth = false,
  variant = "standard",
  label = "",
}) => {
  const isAllSelected = items.length > 0 && values?.length === items.length;

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      onChange(isAllSelected ? [] : items.map((item) => item.value));
      return;
    }
    onChange(value);
  };

  return (
    <FormControl sx={{ m: 1 }} fullWidth={fullWidth}>
      {label && (
        <InputLabel id={"demo-multiple-name-label" + label}>{label}</InputLabel>
      )}
      <Select
        labelId={"demo-multiple-name-label" + label}
        fullWidth={fullWidth}
        multiple
        variant={variant}
        value={values}
        onChange={handleChange}
        renderValue={renderSelectedValues}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              color={"primary"}
              checked={isAllSelected}
              indeterminate={
                values && values.length > 0 && values.length < items.length
              }
            />
          </ListItemIcon>
          <ListItemText primary={selectAllLabel} />
        </MenuItem>
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            <ListItemIcon>
              <Checkbox
                color={"primary"}
                checked={
                  (values?.findIndex(
                    (v: string | number) => v === item.value
                  ) ?? -1) > -1
                }
              />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiselectOption;
