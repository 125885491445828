import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EditPhoneAccountForm } from './EditPhoneAccountForm';
import { initUserAccountService, PhoneUserAccount, UserAccount } from "../security/userAccountService";

export default function EditPhoneAccountPage(props: RouteComponentProps) {
  const params = props.match.params as any;
  
  const accountService = initUserAccountService();

  const [account, setAccount] = useState<UserAccount|null>(null);

  useEffect(() => {
    if(params.accountId) {
      accountService.getUser(params.accountId)?.then(setAccount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.accountId]);
  
  return (
    <>
      {(!params.accountId || !!account) && <EditPhoneAccountForm account={account as PhoneUserAccount} />}
    </>
  );
}