import { SECURITY_API_URL } from "../environment";
import { initAxiosFor } from "./axiosUtils";

export type AddUserAccount = {
  externalUserId: string;
  userProfileId: string;
  type: 'PhoneUserAccount' | 'UsernameUserAccount';
}

export type AddUsernameUserAccount = AddUserAccount & {
  username: string;
}

export type AddPhoneUserAccount = AddUserAccount & {
  phoneNumber: string;
}

export type PhoneUserAccount = AddUserAccount & AddPhoneUserAccount & {
  id: string;
}

export type UserAccount = AddUserAccount & (AddPhoneUserAccount | AddUsernameUserAccount) & {
  id: string;
}

export function initUserAccountService() {
  const axiosInstance = initAxiosFor(SECURITY_API_URL);

  const getUsers = async (): Promise<UserAccount[]> => {
    return (await axiosInstance?.get(`/api/useraccount`))?.data;
  }

  const getUser = async (id: string): Promise<UserAccount> => {
    return (await axiosInstance?.get(`/api/useraccount/${id}`))?.data;
  }

  const getUserFor = async (externalUserId: string): Promise<UserAccount> => {
    return (await axiosInstance?.get(`/api/useraccount/external/${externalUserId}`))?.data;
  }

  const addUserAccount = async (user: AddUsernameUserAccount|AddPhoneUserAccount): Promise<UserAccount> => {
    return (await axiosInstance?.post(`/api/useraccount`, user))?.data;
  }

  const updateUserAccount = async (user: UserAccount): Promise<UserAccount> => {
    return (await axiosInstance?.put(`/api/useraccount/${user.id}`, user))?.data;
  }

  return {
    getUsers,
    getUser,
    getUserFor,
    addUserAccount,
    updateUserAccount,
  };
}