import { Button } from "@mui/material";
import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { patch } from "./patch";
import { useAdAccountService } from "./adAccountService";
import { AccountList } from "./AccountList"
import CircularProgress from '@mui/material/CircularProgress';

export default function AccountsPage(props: RouteComponentProps) {
  const adAccountService = useAdAccountService();

  const [synchronizing, setSynchronizing] = useState(false);

  const sync = () => {
    setSynchronizing(true);
    adAccountService.getAdAccounts()?.then(adAccount => {
      if(!adAccount.length) return;
      const userRoleAssignmentsFetch = (adAccountId: string) => adAccountService.getAdAccountRoleAssignments(adAccountId);
      patch(adAccount, userRoleAssignmentsFetch).then(() => {
        setSynchronizing(false);
      });
    });
  }

  return (
    <>
      <Link style={{ textDecoration: 'none', marginBottom: '1em' }} to="/edit-ad-account">
        <Button variant="contained">New AD Account</Button>
      </Link>
      <Link style={{ textDecoration: 'none', marginLeft: '1em' }} to="/edit-phone-account">
        <Button variant="contained">New Phone Account</Button>
      </Link>
      <Button variant="contained" sx={{marginLeft: '1em'}} onClick={sync}>{synchronizing ? <CircularProgress sx={{ color:'white' }} /> : 'Synchronize With AD'}</Button>
      <AccountList />
    </>
  );
}