import { useTranslation } from "./translation";
import { TimeType } from "./CronBuilder";
import { SelectItem } from "../../shared/SelectOption";
import { formatHour } from "./util";

const minutesArray = Array.from(Array(60).keys());
const hoursArray = Array.from(Array(24).keys());

const normalizeTimeItems = (array: number[]): SelectItem[] => array.map((_, i) => {
  const formatted = formatHour(i);
  return { label: formatted, value: formatted }
});

export function useFrequencyItems(singular: boolean = true, includeDays: boolean = false) {
  const { t } = useTranslation();

  const minuteItems: SelectItem[] = [
    {label: `${t('editSchedule.every')} 30`, value: '30'},
  ];
  
  const hourItems: SelectItem[] = [
    {label: `${t('editSchedule.every')}`, value: '1'},
    ...hoursArray.slice(0, hoursArray.length - 1).map((_, i) => { return { label: `${t('editSchedule.every')} ${i + 2}`, value: i + 2 } }),
  ];

  const frequencyUnitItems: SelectItem[] = [
    { label: singular ? `${t('editSchedule.minute')}` : `${t('editSchedule.minutes')}`, value: 'min' },
    { label: singular ? `${t('editSchedule.hour')}` : `${t('editSchedule.hours')}`, value: 'hour' },
    ...(includeDays ? [
      { label: singular ? `${t('editSchedule.day')}` : `${t('editSchedule.days')}`, value: 'day' },
    ] : [])
  ];
  
  return { minuteItems, hourItems, frequencyUnitItems };
}

const daysInWeekShort = ['MON','TUE','WED','THU','FRI','SAT', 'SUN'];

export function useDayItems() {
  const { t } = useTranslation();
  
  const daysInWeek = [
    t('daysInWeek.Monday'),
    t('daysInWeek.Tuesday'),
    t('daysInWeek.Wednesday'),
    t('daysInWeek.Thursday'),
    t('daysInWeek.Friday'),
    t('daysInWeek.Saturday'),
    t('daysInWeek.Sunday')
  ];
  const daysInWeekItems: SelectItem[] = daysInWeek.map((day, i) => { return { label: day, value: daysInWeekShort[i] } });
  
  return { daysInWeekItems, daysInWeekShort, daysInWeek };
}

const minuteTimeItems: SelectItem[] = normalizeTimeItems(minutesArray);
const hourTimeItems: SelectItem[] = normalizeTimeItems(hoursArray);

export function useTimeItems() {
  const { t } = useTranslation();

  const timeTypeItems: SelectItem[] = [
    {label: `${t('editSchedule.starting_now')}`, value: TimeType.SingleTimeNow},
    {label: `${t('editSchedule.starting_at')}`, value: TimeType.SingleTimeExact},
    {label: `${t('editSchedule.between')}`, value: TimeType.IntervalTime}
  ];
  
  return { timeTypeItems, minuteTimeItems, hourTimeItems };
}

export function useTimeItemsSimple() {
  const { t } = useTranslation();

  const timeTypeItems: SelectItem[] = [
    {label: `${t('editSchedule.starting_at_simple')}`, value: 0},
    {label: `${t('editSchedule.selectedHalfHours')} ${t('editSchedule.between')}`, value: 1},
    {label: `${t('editSchedule.selectedHours')} ${t('editSchedule.between')}`, value: 2},
  ];
  
  return { timeTypeItems, minuteTimeItems, hourTimeItems };
}

const relativeMinutesArray = Array.from(Array(11).keys());
const relativeHoursArray = Array.from(Array(23).keys());
const relativeDaysArray = Array.from(Array(6).keys());

// Every 5th min
const relativeMinuteItems: SelectItem[] = [
  ...relativeMinutesArray.map((_, i) => { return { label: `${(i + 1) * 5}`, value: (i + 1) * 5 } }),
];
const relativeHourItems: SelectItem[] = [
  ...relativeHoursArray.slice(0, hoursArray.length).map((_, i) => { return { label: `${i + 1}`, value: i + 1 } }),
];
const relativeDayItems: SelectItem[] = [
  ...relativeDaysArray.slice(0, relativeDaysArray.length).map((_, i) => { return { label: `${i + 1}`, value: i + 1 } }),
];

export function useRelativeTimeItems() {
  return { minuteItems: relativeMinuteItems, hourItems: relativeHourItems, dayItems: relativeDayItems };
}