import { useEffect, useState } from 'react';
import { AccountCard } from './AccountCard';
import { SearchableList } from '../shared/SearchableList';
import { initUserAccountService, UserAccount } from '../security/userAccountService';

export const AccountList = () => {
  const [accounts, setAccounts] = useState<UserAccount[]>([]);

  useEffect(() => {
    const userSecurityService = initUserAccountService();
    userSecurityService.getUsers().then(accounts => setAccounts(accounts));
  }, []);

  return (
    <>
      <SearchableList
        list={accounts}
        renderElement={(a: UserAccount) => <AccountCard key={a.id} account={a} />}
      />
    </>
  );
}