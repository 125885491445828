import { CronUnit, StatementTree, TimeType } from "./CronBuilder";

function getLowest(value: string): string {
  return value?.includes(',') ? value?.split(',').sort((a, b) => parseInt(a) - parseInt(b))[0] : value;
}

function getHighest(value: string): string | undefined {
  return value?.includes(',') ? value?.slice().split('/')[0]?.split(',').sort((a, b) => parseInt(b) - parseInt(a))[0] : undefined;
}

export class CronParser {
  private parsed: boolean = false;
  private statementTree: StatementTree = {
    second: '*',
    min: '*',
    hour: '*',
    dayInMonth: '?',
    month: '*',
    daysInWeek: '?',
    year: '*',
  }

  public parse(cronStatement: string): StatementTree | undefined {
    const tokens = cronStatement?.split(" ") ?? [];

    if(!tokens.length) return;

    this.statementTree.second = tokens[0];
    this.statementTree.min = tokens[1];
    // Cover every 24h edge case
    this.statementTree.hour = (tokens.indexOf('*') === 4 && tokens.findIndex(e => e.includes('/')) === -1) ? `${tokens[2]}/24` : tokens[2];
    this.statementTree.dayInMonth = tokens[3];
    this.statementTree.month = tokens[4];
    this.statementTree.daysInWeek = tokens[5];
    this.statementTree.year = tokens[6];

    this.parsed = true;

    return this.statementTree;
  }

  public getFrequency(): string | null {
    if (!this.parsed) return null;
    const unit = this.findUnitWithValueMatch(/\//);
    if (unit) return this.splitUnitBy(unit, "/")[1];
    return null;
  }

  public getFrequencyUnit(): CronUnit | null {
    if (!this.parsed) return null;
    return this.findUnitWithValueMatch(/\//);
  }

  public getTimeType(): TimeType | null {
    if (!this.parsed) return null;
    if (this.findUnitWithValueMatch(/-[0-9]{2}\//g))
      return TimeType.IntervalTime;
    if (this.findUnitWithValueMatch(/[0-9]{2}\//g))
      return TimeType.SingleTimeExact;
    return TimeType.SingleTimeNow;
  }

  public getUnitValue(unit: CronUnit): string | null {
    return this.statementTree[unit];
  }

  public getMinuteStart(): string | null {
    return this.getUnitStartTime("min");
  }

  public getMinuteEnd(): string | null {
    return this.getUnitEndTime("min");
  }

  public getHourStart(): string | null {
    return this.getUnitStartTime("hour");
  }

  public getHourEnd(): string | null {
    return this.getUnitEndTime("hour");
  }

  public getDaysInWeek(): string[] | null {
    if (!this.parsed) return null;
    return this.statementTree.daysInWeek.split(",");
  }

  public isParsed(): boolean {
    return this.parsed;
  }

  private getUnitStartTime(unit: CronUnit): string | null {
    if (!this.parsed) return null;
    const tokens = this.splitUnitBy(unit, "-");
    if (tokens.length === 2) return getLowest(tokens[0]);
    const regularTokens = this.splitUnitBy(unit, "/");
    return getLowest(regularTokens.length === 2
      ? regularTokens[0]
      : this.statementTree[unit]);
  }

  private getUnitEndTime(unit: CronUnit): string | null {
    if (!this.parsed) return null;
    const secondToken = this.splitUnitBy(unit, "-")[1];
    if(!secondToken && this.statementTree[unit].includes(',')) return getHighest(this.statementTree[unit]) ?? null;
    if (!secondToken) return null;
    const regularTokens = secondToken.split("/");
    return regularTokens.length === 2 ? regularTokens[0] : secondToken;
  }

  private findUnitWithValueMatch(regex: RegExp): CronUnit | null {
    const units = Object.keys(this.statementTree);
    const index = units.findIndex((u) =>
      this.statementTree[u as CronUnit]?.match(regex)
    );
    if (index !== -1) return units[index] as CronUnit;
    return null;
  }

  private splitUnitBy(unit: CronUnit, separator: string): string[] {
    return this.statementTree[unit]?.split(separator) ?? [];
  }
}
