import { APP_ID, REDIRECT_URL } from '../environment';

const config = {
  appId: APP_ID,
  redirectUri: REDIRECT_URL,
  scopes: [
    "user.read",
    "user.readwrite.all",
    "mailboxsettings.read",
    "calendars.readwrite",
    // "Admin",
    "api://c0570187-e61a-46da-b197-1007ee995a7d/Admin",
  ],
};

export type AppName = 'Tmesh' | 'TmeshSupervisor' | 'TmeshAdmin' | 'Meshframe' | 'Skyland' | 'Sensius' | 'AdminApp' | 'SkylandAdmin' | 'Firemesh';

export const AppServicePrincipals: { [key in AppName]: { servicePrincipalId: string, appRoleId: string } } = {
  Tmesh: {
    servicePrincipalId: '1166876c-2013-4541-a260-b4ecbb73d283',
    appRoleId: 'fbc37fa1-d1d3-4e91-a213-c5a2afddcbd8',
  },
  TmeshSupervisor: {
    servicePrincipalId: '1166876c-2013-4541-a260-b4ecbb73d283',
    appRoleId: '40a94ca3-ec9e-47e1-a343-1a06ab4c76e7',
  },
  TmeshAdmin: {
    servicePrincipalId: '1166876c-2013-4541-a260-b4ecbb73d283',
    appRoleId: '5f5494ff-4c78-4d13-b452-e4afa4eae9d2',
  },
  Meshframe: {
    servicePrincipalId: '4835749f-f487-47a3-95ff-2256fe8c8bd5',
    appRoleId: '3ea51f40-2ad7-4e79-aa18-12c45156dc6a',
  },
  Sensius: {
    servicePrincipalId: 'ab7f5dfe-0b2e-4d6d-b95c-7d7acc319776',
    appRoleId: '1fc23e60-524d-48b4-996d-531658ceb585',
  },
  AdminApp: {
    servicePrincipalId: '392c5c26-ff89-4665-8b69-411e28770ee3',
    appRoleId: 'f7d6234d-0568-48e6-b5f5-8928c09b6be0',
  },
  Skyland: {
    servicePrincipalId: '8843361c-91c0-4a43-8b65-112839a9c3e6',
    appRoleId: '654b092f-b64a-4287-a68b-9b1785e18c91',
  },
  SkylandAdmin: {
    servicePrincipalId: '8843361c-91c0-4a43-8b65-112839a9c3e6',
    appRoleId: '860d4961-043f-4a2c-af7e-d95a47c7b38b',
  },
  Firemesh: {
    servicePrincipalId: 'c8c3311e-2838-44ae-9806-f6bb0869fbf7',
    appRoleId: '44f17297-8939-487d-a297-e795f2868683',
  },
}

export const AppRoleValues: { [key in AppName]: string } = {
  Tmesh: "Bitmesh.Tempmesh.Permission",
  TmeshSupervisor: "Bitmesh.Tmesh.Supervisort",
  TmeshAdmin: "Bitmesh.Tmesh.Admin",
  Meshframe: "Meshframe.Test.Application.Permission",
  Sensius: "Bitmesh.Sensius.Permission",
  AdminApp: "Bitmesh.Admin.Permission",
  Skyland: "Bitmesh.Skyland.Permission",
  SkylandAdmin: "Bitmesh.Skyland.Admin",
  Firemesh: "Bitmesh.Firemesh.Permission",
}

export const AllowedDomains = [
  '@tmesh.app',
  '@tmesh.no',
  '@bitme.sh',
  '@bitmesh.no',
  '@bitmeshas.onmicrosoft.com',
  '@firemesh.io',
  '@firemesh.no',
  '@sensius.no',
  '@skyland.no',
];

export const BITMESH_TENANT = '36e67390-6db0-4f30-b85f-4770c90ad437';

export const AppNames: AppName[] = Object.keys(AppServicePrincipals) as any;

export default config;
