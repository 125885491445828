interface EnvironmentConfig {
  ENTITY_API_URL: string;
  CHECKLIST_API_URL: string;
  FILE_API_URL: string;
  SECURITY_API_URL: string;
  REDIRECT_URL: string;
  APP_ID: string;
}

const w: { _env_: EnvironmentConfig; } = window as any;

export const REDIRECT_URL = w._env_.REDIRECT_URL;
export const APP_ID = w._env_.APP_ID;
export const ENTITY_API_URL = w._env_.ENTITY_API_URL;
export const FILE_API_URL = w._env_.FILE_API_URL;
export const SECURITY_API_URL = w._env_.SECURITY_API_URL;
export const CHECKLIST_API_URL = w._env_.CHECKLIST_API_URL;
