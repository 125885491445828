import * as cron from "cron";
import { Moment } from "moment";

export function formatHour(i: number): string {
  return `${i}`.length < 2 ? `0${i}` : `${i}`;
}

export function getNextDatesFromCronStatement(statement: string, n: number = 3): Moment[] {
  if(!isCronValid(statement)) return [];
  return getCronJobFromStatement(statement).nextDates(n) as Moment[];
}

export function isCronValid(statement: string): boolean {
  try {
    getCronJobFromStatement(statement);    
    return true;
  } catch (e) {
    return false;
  }
}

function getCronJobFromStatement(statement: string) {
  return new cron.CronJob(
    statement.slice(0, -2).replace("?", "*"),
    () => {}
  );
}