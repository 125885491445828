import { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SelectOption from "../shared/SelectOption";

type Props = {
  label?: string;
  targetTenantId: string;
  allTenants: any[];
  open: boolean;
  onClose?: () => any;
  handleImport: (sourceTenantId: string, targetTenantId: string) => any;
};

export const ImportDialog: FC<Props> = ({
  label = "",
  targetTenantId,
  allTenants,
  open,
  onClose = () => {},
  handleImport = () => {}
}) => {
  const [successOpen, setSuccessOpen] = useState<boolean>(false);

  const [tenantId, setTenantId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Import {label}</Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <label>Import {label} from Tenant:</label>{" "}
        <SelectOption
          value={tenantId}
          onChange={(v: string) => setTenantId(v)}
          items={allTenants?.map((t: any) => ({ label: t.name, value: t.id })) ?? []}
        />
        <br/>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => {
          handleImport(tenantId, targetTenantId)
            .then(() => setSuccessOpen(true))
            .catch(() => setErrorMessage("Something went wrong, please try again."));
        }}>Import</Button>
      </DialogActions>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          Imported successfully!
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
