import { FC } from "react";
import {
  Select,
  MenuItem,
} from "@mui/material";

export interface SelectItem {
  label: string;
  value: string|number;
}

type Props = {
  items: SelectItem[];
  value?: string|number;
  onChange?: any;
};

const SelectOption: FC<Props> = ({ items, value, onChange }) => {
  return (
    <Select
      variant="standard"
      value={value}
      onChange={e => onChange(e.target.value)}
      displayEmpty
    >
      {items.map((item, i) => <MenuItem key={i} value={item.value}>{item.label}</MenuItem>)}
    </Select>
  )
}

export default SelectOption;
