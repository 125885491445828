import React, { FC, useEffect, useState } from "react";
import SelectOption from "../../shared/SelectOption";
import MultiselectOption from "../../shared/MulitselectOption";
import { CronBuilder, CronUnit, TimeType } from './CronBuilder';
import { CronParser } from './CronParser';
import { useTranslation } from "./translation";
import { useDayItems, useFrequencyItems, useTimeItems } from './hooks';

type Props = {
  prefix?: any;
  suffix?: any;
  value?: string;
  onChange?: (cronStatement: string) => void;
}

const DefaultFrequencies = {
  min: '30',
  hour: '1',
};

const CronDefinitionField: FC<Props> = ({ prefix, suffix, value, onChange }) => {
  const { t } = useTranslation();

  const cronParser = new CronParser();
  value && cronParser.parse(value);

  const [frequency, setFrequency] = useState(cronParser.getFrequency() || '30');
  const [frequencyUnit, setFrequencyUnit] = useState(cronParser.getFrequencyUnit() || 'min');

  const { daysInWeekItems, daysInWeekShort, daysInWeek } = useDayItems();
  const { hourItems, minuteItems, frequencyUnitItems } = useFrequencyItems(frequency === '1');
  const { hourTimeItems, minuteTimeItems, timeTypeItems } = useTimeItems();

  const [minuteStart, setMinuteStart] = useState(cronParser.getMinuteStart() || '00');
  const [minuteEnd, setMinuteEnd] = useState(cronParser.getMinuteEnd() || '00');

  const [hourStart, setHourStart] = useState(cronParser.getHourStart() || '00');
  const [hourEnd, setHourEnd] = useState(cronParser.getHourEnd() || '00');

  const [timeType, setTimeType] = useState(cronParser.getTimeType() || TimeType.SingleTimeNow);

  const [selectedDaysInWeek, setSelectedDaysInWeek] = useState(cronParser.getDaysInWeek() || [...daysInWeekShort]);

  const [cronStatement, setCronStatement] = useState('');

  // Emit change
  useEffect(() => {
    onChange && onChange(cronStatement);
  }, [cronStatement]);

  // Edge case for time type Now -> Interval|Exact change
  useEffect(() => {
    if(timeType === TimeType.SingleTimeExact || timeType === TimeType.IntervalTime) {
      minuteStart === '*' && setMinuteStart('00');
      hourStart === '*' && setHourStart('00');
    }
  }, [timeType]);

  // Build the cron statement when any of the params change
  useEffect(() => {
    const builder = new CronBuilder()
      .setByTimeType('min', timeType, minuteStart, minuteEnd)
      .setByTimeType('hour', timeType, hourStart, hourEnd)
      .setFrequency(frequencyUnit as CronUnit, frequency)
      .set('daysInWeek', selectedDaysInWeek.join(','));
    
    // Handle hour edge cases
    if(frequencyUnit === 'hour' && timeType === TimeType.SingleTimeNow) {
      // The minute must have exact start time because if it is a * it will be run on every minute, and not every hour as intended.
      builder.setByTimeType('min', TimeType.SingleTimeExact, '00', '00');
      if (frequency == '24') {
        // The hour must have exact start time because if it is a * it will be run on every hour, and not every day as intented.
        builder.setByTimeType('hour', TimeType.SingleTimeExact, '00', '00');
      }
    }
    // NQuartz does not accept /24 frequency, so it must be unset
    if(frequencyUnit === 'hour' && frequency == '24') {
      builder.unsetFrequency('hour');
    }
    setCronStatement(builder.build());
  }, [frequency, frequencyUnit, minuteStart, minuteEnd, hourStart, hourEnd, timeType, selectedDaysInWeek]);

  return (
    <>
      {prefix}
      <SelectOption
        value={frequency}
        onChange={setFrequency}
        items={frequencyUnit === 'min' ? minuteItems : hourItems}
      />
      <span> </span>
      <SelectOption
        value={frequencyUnit}
        onChange={(v: 'min' | 'hour') => {
          setFrequencyUnit(v);
          setFrequency(DefaultFrequencies[v]);
        }}
        items={frequencyUnitItems}
      />
      <span> </span>
      <SelectOption
        value={timeType}
        onChange={(v: string) => setTimeType(parseInt(v))}
        items={timeTypeItems}
      />
      {timeType !== TimeType.SingleTimeNow &&
      <>
        <span> </span>
        <SelectOption
          value={hourStart}
          onChange={setHourStart}
          items={hourTimeItems}
        />
        <span> : </span>
        <SelectOption
          value={minuteStart}
          onChange={setMinuteStart}
          items={minuteTimeItems}
        />
      </>}
      {timeType === TimeType.IntervalTime && <>
        <span> </span>
        {t('and')}
        <span> </span>
        <SelectOption
          value={hourEnd}
          onChange={setHourEnd}
          items={hourTimeItems}
        />
        <span> : </span>
        <SelectOption
          value={minuteEnd}
          onChange={setMinuteEnd}
          items={minuteTimeItems}
        />
      </>}
      <span> </span>
      {t('on')}
      <span> </span>
      <MultiselectOption
        values={selectedDaysInWeek}
        onChange={setSelectedDaysInWeek}
        items={daysInWeekItems}
        selectAllLabel={`${t('editSchedule.every_day_of_the_week')}`}
        renderSelectedValues={(values: any) => 
          values.length === daysInWeekItems.length ? `${t('editSchedule.every_day_of_the_week')}` : 
            values.map((value: any) => daysInWeek[daysInWeekShort.findIndex(v => v === value)]).join(", ")}
      />
      .
      {suffix}
    </>
  )
}

export default CronDefinitionField;
