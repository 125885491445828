import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import "bootstrap/dist/css/bootstrap.css";
import { RecoilRoot } from "recoil";

import ProvideAppContext from "./AppContext";
import { AppLayout } from "./AppLayout";
import { AppRoutes } from "./AppRoutes";

type AppProps = {
  pca: IPublicClientApplication;
};

export default function App({ pca }: AppProps) {
  return (
    <RecoilRoot>
      <MsalProvider instance={pca}>
        <ProvideAppContext>
          <Router>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </Router>
        </ProvideAppContext>
      </MsalProvider>
    </RecoilRoot>
  );
}
