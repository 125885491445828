import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { List, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import PushPinIcon from '@mui/icons-material/PushPin';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const SidebarItems = () => {
  const history = useHistory();

  const navigateTo = (to: string) => () => history.push(to);

  return (
    <List>
      <ListItem button onClick={navigateTo("/")}>
        <Tooltip title="Dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Dashboard"  />
      </ListItem>
      <ListItem button onClick={navigateTo("/accounts")}>
        <Tooltip title="Accounts">
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Accounts" />
      </ListItem>
      <ListItem button onClick={navigateTo("/profiles")}>
        <Tooltip title="Profiles">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Profiles" />
      </ListItem>
      <ListItem button onClick={navigateTo("/sites")}>
        <Tooltip title="Sites">
          <ListItemIcon>
            <PushPinIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Sites" />
      </ListItem>
      <ListItem button onClick={navigateTo("/tenants")}>
        <Tooltip title="Tenants">
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Tenants" />
      </ListItem>
    </List>
  );
}