import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SidebarItems } from './SidebarItems';
import { Drawer } from './Drawer';
import { Toolbar, Typography } from '@mui/material';

type Props = {
  open: boolean;
  toggleSidebar: () => void;
}

export const Sidebar: React.FC<Props> = ({ open, toggleSidebar }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
        }}
      >
        <Typography variant="h4">Admesh</Typography>
        <IconButton onClick={toggleSidebar}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <SidebarItems />
    </Drawer>
  );
}