import { useState } from "react";
import {
  Nav,
  NavItem,
} from "react-bootstrap";

import { useAppContext } from "../core/AppContext";
import { PhoneSignDialog } from "../security/PhoneSignDialog";
import { removeRefreshToken, removeToken } from "../security/token";


export const NavMenu = () => {
  const app = useAppContext();

  const [phoneSignOpen, setPhoneSignOpen] = useState(false);

  const signOut = () => {
    if(!app.signOut) return;
    app.signOut();
    removeToken();
    removeRefreshToken();
  }

  return (
    <Nav className="ms-auto align-items-center" navbar>
      {app.isAuthenticated ?
        <NavItem>
          <Nav.Link style={{ color: "#fff" }} onClick={signOut}>
            Sign Out
          </Nav.Link>
        </NavItem>
      :
      <>
        <NavItem>
          <Nav.Link style={{ color: "#fff" }} onClick={app.signIn!}>
            Sign In With MSAL
          </Nav.Link>
        </NavItem>
        <NavItem>
          <Nav.Link style={{ color: "#fff" }} onClick={() => setPhoneSignOpen(true)}>
            Sign In With Phone
          </Nav.Link>
        </NavItem>
      </>}

      <PhoneSignDialog
        open={phoneSignOpen}
        onSuccess={() => {
          setPhoneSignOpen(false);
          app.setAuthenticated(true);
        }}
        onClose={() => setPhoneSignOpen(false)}
      />
    </Nav>
  );
};
