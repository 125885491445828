import { AxiosInstance } from "axios";
import { SECURITY_API_URL } from "../environment";
import { getRefreshToken, getToken, setRefreshToken, setToken } from "./token";

export async function refreshToken(axiosInstance: AxiosInstance): Promise<boolean> {
	const jwtToken = getToken();
	const refreshTokenId = getRefreshToken();

	try {
		const resp = await axiosInstance.post(`${SECURITY_API_URL}/api/authentication/refresh-token`, { jwtToken, refreshTokenId });

		setToken(resp.data.jwtToken);
		setRefreshToken(resp.data.refreshTokenId);

		return true;
	} catch(error) {
		return false;
	}

}