import { ENTITY_API_URL } from "../environment";
import { initAxiosFor } from "../security/axiosUtils";

export function useTenantService() {
  const axiosInstance = initAxiosFor(ENTITY_API_URL);
  return {
    getAll: async () => (await axiosInstance.get(`${ENTITY_API_URL}/api/tenants`))?.data,
    getOne: async (id: string) => (await axiosInstance.get(`${ENTITY_API_URL}/api/tenants/` + id))?.data,
    post: async (value: any, includeTypes: boolean) => (await axiosInstance.post(`${ENTITY_API_URL}/api/tenants`, value, { params: { includeTypes } }))?.data,
    put: async (id: string, value: any) => (await axiosInstance.put(`${ENTITY_API_URL}/api/tenants/` + id, value))?.data,
  }
}