import React, {
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";

import config from "./AppConfig";
import {
  SilentRequest,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { getAccess } from "../security/getAccess";
import { refreshToken } from "../security/refreshToken";
import axios from "axios";
import { initUserProfileService, UserProfile } from "../security/userProfileService";
import { SECURITY_API_URL } from "../environment";
import { urlToScopeKey } from "../route-utils";

type AppContext = {
  isAuthenticated: boolean;
  setAuthenticated: (v: boolean) => any;
  user?: UserProfile;
  signIn?: () => void;
  signOut?: () => void;
};

const appContext = createContext<AppContext>({
  isAuthenticated: false,
  setAuthenticated: () => {},
  user: undefined,
  signIn: undefined,
  signOut: undefined,
});

export function useAppContext(): AppContext {
  return useContext(appContext);
}

interface ProvideAppContextProps {
  children: React.ReactNode;
}

export default function ProvideAppContext({
  children,
}: ProvideAppContextProps) {
  const auth = useProvideAppContext();

  return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

export function useProvideAppContext() {
  const msal = useMsal();

  const [isAuthenticated, setAuthenticated] = useState<boolean>(!!msal.instance.getActiveAccount());

  useEffect(() => {
    if(!isAuthenticated) {
      refreshToken(axios.create()).then(setAuthenticated);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getOwnUserProfile } = initUserProfileService();

  const [userProfile, setUserProfile] = useState<UserProfile>();

  useEffect(() => {
    isAuthenticated && getOwnUserProfile().then(setUserProfile).catch(console.log);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);


  const scopeConfig: Map<string, Array<string>> = new Map<
    string,
    Array<string>
  >([
    [
      SECURITY_API_URL,
      ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
  ]);

  const acquireTokenSilent = (url: string) => {
    var baseApi = urlToScopeKey(url);
    const scopes = scopeConfig.get(baseApi);

    const accesConfig: SilentRequest = {
      account: msal.instance.getActiveAccount()!,
      scopes: scopes ?? [],
    };
    return msal.instance.acquireTokenSilent(accesConfig);
  };

  useEffect(() => {
    if (msal.instance.getActiveAccount()) {
      acquireTokenSilent(SECURITY_API_URL).then(result => getAccess(result.accessToken));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async () => {
    const scopes = scopeConfig.get(SECURITY_API_URL);

    const result = await msal.instance.loginPopup({
      authority: "https://login.microsoftonline.com/577c80ff-449e-4fb3-897f-269d41e57e8b",
      scopes: scopes ?? [],
      prompt: "select_account",
    });

    getAccess(result.accessToken).then(() => setAuthenticated(true));
  };

  const signOut = async () => {
    await msal.instance.logoutPopup();
    setAuthenticated(false);
  };

  return {
    isAuthenticated,
    setAuthenticated,
    user: userProfile,
    signIn,
    signOut,
  };
}
