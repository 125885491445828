import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAdAccountService } from "./adAccountService";
import { EditAdAccountForm } from './EditAdAccountForm';
import { AppRoleAssignment, User } from "microsoft-graph";

export default function EditAdAccountPage(props: RouteComponentProps) {
  const params = props.match.params as any;
  
  const adAccountService = useAdAccountService();

  const [adAccount, setAdAccount] = useState<User|null>(null);
  const [userRoleAssignments, setUserRoleAssignments] = useState<AppRoleAssignment[]|null>(null);

  useEffect(() => {
    if(params.accountId) {
      adAccountService.getAdAccount(params.accountId)?.then(setAdAccount);
      adAccountService.getAdAccountRoleAssignments(params.accountId)?.then(setUserRoleAssignments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.accountId]);
  
  return (
    <>
      {(!params.accountId || !!adAccount) && <EditAdAccountForm adAccount={adAccount} adAccountRoleAssignments={userRoleAssignments} />}
    </>
  );
}