import { Button } from "@mui/material";
import { Link, RouteComponentProps } from "react-router-dom";
import { ProfilesList } from "./ProfilesList"

export default function ProfilesPage(props: RouteComponentProps) {
  return (
    <>
      <Link style={{ textDecoration: 'none', marginBottom: '1em' }} to="/edit-profile">
        <Button variant="contained">New Profile</Button>
      </Link>
      <ProfilesList />
    </>
  );
}