import { useEffect, useState } from 'react';
import { useTenantService } from './tenantService';
import { TenantCard } from './TenantCard';
import { SearchableList } from '../shared/SearchableList';

export const TenantsList = () => {
  const tenantService = useTenantService();

  const [tenants, setTenants] = useState<any>(null);

  useEffect(() => {
    tenantService.getAll().then(setTenants);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <SearchableList
        list={tenants}
        renderElement={(t: any) => <TenantCard allTenants={tenants} key={t.id} tenant={t} />}
      />
    </>
  );
}