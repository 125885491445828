import { IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { CopyIcon } from '../shared/CopyIcon';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useState } from 'react';
import { ImportDialog } from './ImportDialog';
import { useChecklistService } from './checklistService';
import { useEntityTypesService } from './entityTypesService';

type Props = {
  tenant: any;
  allTenants: any[];
}

export const TenantCard: React.FC<Props> = ({ tenant, allTenants }) => {

  const [routinesOpen, setRoutinesOpen] = useState(false);
  const checklistService = useChecklistService();

  const [entityTypesOpen, setEntityTypesOpen] = useState(false);
  const entityTypesService = useEntityTypesService();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{tenant.name}</Typography>
          <Typography variant="h6"> <AssignmentIndIcon /> {tenant.id} <CopyIcon text={tenant.id} /></Typography>
          {tenant.description ? <Typography variant="h6"> <DescriptionIcon /> {tenant.description} </Typography> : <></>}
          {tenant.notes ? <Typography variant="h6"> <NoteAltIcon /> {tenant.notes} </Typography> : <></>}
        </div>
        <div>
          <Tooltip title="Import">
            <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}>
              <ImportExportIcon />
            </IconButton>
          </Tooltip>
          <Link to={`/edit-tenant/${tenant.id}`}>
            <EditIcon />
          </Link>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => {
            setRoutinesOpen(true);
            setAnchorEl(null);
          }}>
            Routines
          </MenuItem>
          <MenuItem onClick={() => {
            setEntityTypesOpen(true);
            setAnchorEl(null);
          }}>
            Entity Types
          </MenuItem>
        </Menu>
      </div>
      <ImportDialog
        label="Routines"
        allTenants={allTenants}
        targetTenantId={tenant.id}
        open={routinesOpen}
        handleImport={(sourceTenantId: string, targetTenantId: string) => {
          return checklistService.import(sourceTenantId, targetTenantId);
        }}
        onClose={() => setRoutinesOpen(false)}
      />
      <ImportDialog
        label="Entity Types"
        allTenants={allTenants}
        targetTenantId={tenant.id}
        open={entityTypesOpen}
        handleImport={(sourceTenantId: string, targetTenantId: string) => {
          return entityTypesService.import(sourceTenantId, targetTenantId);
        }}
        onClose={() => setEntityTypesOpen(false)}
      />
    </Paper>
  );
}