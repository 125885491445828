import { useEffect, useState } from 'react';
import { ProfileCard } from './ProfileCard';
import { SearchableList } from '../shared/SearchableList';
import { initUserProfileService, UserProfile } from '../security/userProfileService';

export const ProfilesList = () => {

  const [users, setUsers] = useState<UserProfile[]>([]);

  useEffect(() => {
    const userProfileService = initUserProfileService();
    userProfileService.getUserProfiles().then(users => setUsers(users));
  }, []);

  return (
    <>
      <SearchableList
        list={users}
        renderElement={(u: UserProfile) => <ProfileCard key={u.id} profile={u} />}
      />
    </>
  );
}