import { AppRoleAssignment, User } from "microsoft-graph";
import { AppServicePrincipals, AppName } from "../core/AppConfig";
import { initGraphApiService } from "../security/graphApiService";

function getAdAccountObject(
  userPrincipalName: string,
  displayName: string | null,
  password: string | null,
  tenant: string,
  sites: string,
  forceChangePassword: boolean
): any {
  const user = {
    accountEnabled: true,
    ...(displayName ? { displayName } : {}),
    passwordPolicies: "DisablePasswordExpiration",
    ...(password
      ? {
          passwordProfile: {
            password: password,
            forceChangePasswordNextSignIn: forceChangePassword,
          },
        }
      : {}),
    preferredLanguage: "nb-NO",
    usageLocation: "NO",
    userPrincipalName: userPrincipalName,
    mailNickname: userPrincipalName?.split("@")[0],
    onPremisesExtensionAttributes: {
      extensionAttribute1: tenant,
      extensionAttribute2: sites,
      extensionAttribute3: null,
      extensionAttribute4: null,
      extensionAttribute5: null,
      extensionAttribute6: null,
      extensionAttribute7: null,
      extensionAttribute8: null,
      extensionAttribute9: null,
      extensionAttribute10: null,
      extensionAttribute11: null,
      extensionAttribute12: null,
      extensionAttribute13: null,
      extensionAttribute14: null,
      extensionAttribute15: null,
    },
  };
  return user;
}

async function updateAppRoleAssignments(
  userId: string,
  selectedApps: AppName[],
  unselectedApps?: AppRoleAssignment[]
) {
  const { addAppRoleAssignment, removeAppRoleAssignment } = initGraphApiService();

  await Promise.all([
    ...selectedApps.map(
      async (app: AppName) =>
        await addAppRoleAssignment(
          userId,
          {
            principalId: userId,
            principalType: "user",
            resourceId: AppServicePrincipals[app].servicePrincipalId,
            appRoleId: AppServicePrincipals[app].appRoleId
          }
        )
    ),
    ...(unselectedApps
      ? [
          ...unselectedApps?.filter(v => !!v).map(
            async (appRoleAssignment: AppRoleAssignment) =>
              await removeAppRoleAssignment(
                userId!,
                appRoleAssignment.id!
              )
          ),
        ]
      : []),
  ]);
}

export function useAdAccountService() {
  const { addUser, updateUser, getUser, getUsers, getUserRoleAssignments } = initGraphApiService();

  return {
    addAdAccount: async (
      userPrincipalName: string,
      displayName: string,
      password: string,
      tenant: string,
      sites: string,
      selectedApps: AppName[],
      forceChangePassword: boolean = true,
      userProfileId: string|null = null
    ) => {
      const user = getAdAccountObject(
        userPrincipalName,
        displayName,
        password,
        tenant,
        sites,
        forceChangePassword
      );
      try {
        const result = await addUser(user, userProfileId);
        await updateAppRoleAssignments(result?.id!, selectedApps);
      } catch (e) {
        console.error(e);
      }
    },
    updateAdAccount: async (
      id: string,
      userPrincipalName: string,
      displayName: string,
      tenant: string,
      sites: string,
      newlySelectedApps: AppName[],
      unselectedApps: AppRoleAssignment[],
      forceChangePassword: boolean = true,
      userProfileId: string|null = null
    ) => {
      const user = getAdAccountObject(
        userPrincipalName,
        displayName,
        null,
        tenant,
        sites,
        forceChangePassword
      );
      try {
        await updateUser(id, user, userProfileId);
        await updateAppRoleAssignments(id, newlySelectedApps, unselectedApps);
      } catch (e) {
        console.error(e);
      }
    },
    getAdAccounts: (): Promise<User[]> => {
      return getUsers();
    },
    getAdAccount: (id: string): Promise<User | null> => {
      return getUser(id);
    },
    getAdAccountRoleAssignments: (userId: string): Promise<AppRoleAssignment[]> => {
      return getUserRoleAssignments(userId);
    },
  };
}
