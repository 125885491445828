import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSiteService } from "./siteService";
import { EditSiteForm } from './EditSiteForm';

export default function EditSitePage(props: RouteComponentProps) {
  const params = props.match.params as any;
  
  const siteService = useSiteService();

  const [site, setSite] = useState<any>(null);

  useEffect(() => {
    params.siteId && siteService.getOne(params.siteId).then(setSite);
  }, [params.siteId]);
  
  return (
    <>
      {(!params.siteId || !!site) && <EditSiteForm site={site} />}
    </>
  );
}