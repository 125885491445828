import { FC, useEffect, useState } from "react";
import SelectOption from "../../shared/SelectOption";
import MultiselectOption from "../../shared/MulitselectOption";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CronBuilder, TimeType } from "./CronBuilder";
import { CronParser } from "./CronParser";
import { useTranslation } from "./translation";
import { useDayItems, useTimeItemsSimple } from "./hooks";
import { formatHour } from "./util";

function getCronSimpleFrequency(cron: string): SimpleFrequency {
  const cronParser = new CronParser();
  cronParser.parse(cron);
  if (!cronParser.isParsed()) return SimpleFrequency.HalfHour;
  return cronParser.getUnitValue("hour")?.includes(",")
    ? SimpleFrequency.SelectedHours
    : SimpleFrequency.HalfHour;
}

export function isCronGeneratedInSimpleUi(cron: string): boolean {
  const cronParser = new CronParser();
  cronParser.parse(cron);
  return cronParser.isParsed() && cronParser.getFrequency() === "24";
}

type Props = {
  prefix?: any;
  suffix?: any;
  value?: string;
  onChange?: (cronStatement: string) => void;
};

enum SimpleFrequency {
  HalfHour,
  SelectedHours,
}

const SimpleCronDefinitionField: FC<Props> = ({
  prefix,
  suffix,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const cronParser = new CronParser();
  value && cronParser.parse(value);

  const { daysInWeekItems, daysInWeekShort, daysInWeek } = useDayItems();
  const { hourTimeItems, timeTypeItems } = useTimeItemsSimple();

  const [frequency, setFrequency] = useState(getCronSimpleFrequency(value ?? ''));

  const [hourStart, setHourStart] = useState(cronParser.getHourStart() || "00");
  const [hourEnd, setHourEnd] = useState(cronParser.getHourEnd() || "00");
  const [selectedHours, setSelectedHours] = useState(
    cronParser?.getUnitValue("hour")?.split("/")[0]?.split(",") ?? []
  );
  const [hours, setHours] = useState<string[]>([]);

  const [timeType, setTimeType] = useState(
    cronParser.getUnitValue("hour")?.includes(",") ||
      cronParser.getUnitValue("min")?.includes(",")
      ? TimeType.IntervalTime
      : TimeType.SingleTimeExact
  );

  const [timeTypeSelection, setTimeTypeSelection] = useState(
    cronParser?.getUnitValue("hour")?.includes(",")
      ? 2 : (cronParser?.getUnitValue("min")?.includes(",") ? 1 : 0)
  );

  const [selectedDaysInWeek, setSelectedDaysInWeek] = useState(
    cronParser.getDaysInWeek() || [...daysInWeekShort]
  );

  const [cronStatement, setCronStatement] = useState("");

  // Edge case HalfHour -> SelectedHours with '-' included
  useEffect(() => {
    if (frequency === SimpleFrequency.SelectedHours) {
      selectedHours.find((e) => e.includes("-")) && setSelectedHours([]);
    }
  }, [frequency]);

  // Emit change
  useEffect(() => {
    onChange && onChange(cronStatement);
  }, [cronStatement]);

  // Build the cron statement when any of the params change
  useEffect(() => {
    const builder = new CronBuilder()
      .setByTimeType("min", timeType, "00", "00")
      .set("daysInWeek", selectedDaysInWeek.join(","));

    if (
      timeType === TimeType.IntervalTime &&
      frequency === SimpleFrequency.SelectedHours
    ) {
      if (!selectedHours.length) {
        setCronStatement("");
        return;
      }
      builder.setByTimeType(
        "hour",
        TimeType.SingleTimeExact,
        selectedHours.join(","),
        ""
      );
    } else if (
      timeType === TimeType.IntervalTime &&
      frequency === SimpleFrequency.HalfHour
    ) {
      builder
        .setByTimeType("min", TimeType.SingleTimeExact, "00,30")
        .setByTimeType("hour", timeType, hourStart, hourEnd);
    } else {
      builder.setByTimeType("hour", timeType, hourStart, hourEnd);
    }

    setCronStatement(builder.build());
  }, [
    hourStart,
    hourEnd,
    selectedHours,
    frequency,
    timeType,
    selectedDaysInWeek,
  ]);

  useEffect(() => {
    if (timeType !== TimeType.IntervalTime) return;

    const numOfHours = parseInt(hourEnd) - parseInt(hourStart);
    if (numOfHours < 0 || isNaN(numOfHours)) {
      setCronStatement("");
      return;
    }
    const hourOptions = Array.from(Array(numOfHours + 1).keys()).map((_, i) =>
      formatHour(i + parseInt(hourStart))
    );

    setHours(hourOptions);
  }, [hourStart, hourEnd, timeType]);

  useEffect(() => {
    if(hourStart === '*') setHourStart('00');
  }, [hourStart])

  return (
    <>
      {prefix}
      <SelectOption
        value={timeTypeSelection}
        onChange={(v: 0 | 1 | 2) => {
          const handlers = {
            0: () => setTimeType(TimeType.SingleTimeExact),
            1: () => {
              setTimeType(TimeType.IntervalTime);
              setFrequency(SimpleFrequency.HalfHour)
            },
            2: () => {
              setTimeType(TimeType.IntervalTime);
              setFrequency(SimpleFrequency.SelectedHours);
            },
          };
          handlers[v]();
          setTimeTypeSelection(v);
        }}
        items={timeTypeItems}
      />
      <>
        <span> </span>
        <SelectOption
          value={hourStart}
          onChange={setHourStart}
          items={hourTimeItems.map((v) => ({ ...v, label: `${v.label}:00` }))}
        />
      </>
      {timeType === TimeType.IntervalTime && (
        <>
          <span> </span>
          {t("and")}
          <span> </span>
          <SelectOption
            value={hourEnd}
            onChange={setHourEnd}
            items={hourTimeItems.map((v) => ({ ...v, label: `${v.label}:00` }))}
          />
        </>
      )}
      <span> </span>
      {t("on")}
      <span> </span>
      <MultiselectOption
        values={selectedDaysInWeek}
        onChange={setSelectedDaysInWeek}
        items={daysInWeekItems}
        selectAllLabel={`${t("editSchedule.every_day_of_the_week")}`}
        renderSelectedValues={(values: any) =>
          values.length === daysInWeekItems.length
            ? `${t("editSchedule.every_day_of_the_week")}`
            : values
                .map(
                  (value: any) =>
                    daysInWeek[daysInWeekShort.findIndex((v) => v === value)]
                )
                .join(", ")
        }
      />
      .
      {timeType === TimeType.IntervalTime &&
        (!!hours.length ? true : "") &&
        frequency !== SimpleFrequency.HalfHour && (
          <>
            <br />
            <ToggleButtonGroup
              size="small"
              value={selectedHours}
              onChange={(_, values) => setSelectedHours(values)}
              style={{
                display: "flex",
                flexFlow: "row",
                flexWrap: "wrap",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              {hours.map((h) => (
                <ToggleButton value={h}>{h}:00</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}
      {suffix}
    </>
  );
};

export default SimpleCronDefinitionField;
