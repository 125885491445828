import { useEffect, useState } from 'react';
import { useSiteService } from './siteService';
import { SiteCard } from './SiteCard';
import { SearchableList } from '../shared/SearchableList';

export const SitesList = () => {
  const siteService = useSiteService();

  const [sites, setSites] = useState<any>(null);

  useEffect(() => {
    siteService.getAll().then(setSites);
  }, []);
  
  return (
    <>
      <SearchableList
        list={sites}
        renderElement={(s: any) => <SiteCard key={s.id} site={s} />}
      />
    </>
  );
}