import { Paper, Typography } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link } from 'react-router-dom';
import { CopyIcon } from '../shared/CopyIcon'

type Props = {
  site: any;
}

export const SiteCard: React.FC<Props> = ({ site }) => {
  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{site.name}</Typography>
          <Typography variant="h6">{site.siteType}</Typography>
          <AssignmentIndIcon /> {site.id} <CopyIcon text={site.id} />
          <br />
          <LocalPhoneIcon /> {site.phoneNumber?.split(",")?.join(", ")}
          <br />
          <HomeIcon /> {site.address || "Not specified"}
        </div>
        <div>
          <Link to={`/edit-site/${site.id}`}>
            <EditIcon />
          </Link>
        </div>
      </div>
    </Paper>
  );
}