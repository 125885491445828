import { SECURITY_API_URL } from "../environment";
import { initAxiosFor } from "./axiosUtils";


export type AddUserProfile = {
  name: string;
  roles: string[];
  tenantIds: string[];
  fullAccessTenantIds?: string[];
  siteIds: string[];
  isPublic: boolean;
  permissions: string[];
}

export type UserProfile = AddUserProfile & {
  id: string;
}

export function initUserProfileService() {
  const axiosInstance = initAxiosFor(SECURITY_API_URL);

  const getUserProfiles = async (): Promise<UserProfile[]> => {
    return (await axiosInstance?.get(`/api/userprofile`))?.data;
  }

  const getOwnUserProfile = async (): Promise<UserProfile> => {
    return (await axiosInstance?.get(`/api/userprofile/me`))?.data;
  }

  const getUserProfileById = async (profileId: string): Promise<UserProfile> => {
    return (await axiosInstance?.get(`/api/userprofile/${profileId}`))?.data;
  }

  const getUserProfile = async (profileId: string): Promise<UserProfile> => {
    return (await axiosInstance?.get(`/api/userprofile/user/${profileId}`))?.data;
  }

  const getUserProfileFor = async (externalUserId: string): Promise<UserProfile> => {
    return (await axiosInstance?.get(`/api/userprofile/external/${externalUserId}`))?.data;
  }

  const addUserProfile = async (userProfile: AddUserProfile): Promise<UserProfile> => {
    return (await axiosInstance?.post(`/api/userprofile`, userProfile))?.data;
  }

  const updateUserProfile = async (userProfile: UserProfile): Promise<UserProfile> => {
    return (await axiosInstance?.put(`/api/userprofile/${userProfile.id}`, userProfile))?.data;
  }

  const addOrUpdateUserProfileFor = async (userId: string, userProfile: AddUserProfile): Promise<UserProfile> => {
    return (await axiosInstance?.put(`/api/userprofile/external/${userId}`, userProfile))?.data;
  }

  return {
    getUserProfiles,
    getOwnUserProfile,
    getUserProfileById,
    getUserProfile,
    getUserProfileFor,
    addUserProfile,
    updateUserProfile,
    addOrUpdateUserProfileFor,
  };
}