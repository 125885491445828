import { Button } from "@mui/material";
import { Link, RouteComponentProps } from "react-router-dom";
import { SitesList } from "./SitesList"

export default function SitesPage(props: RouteComponentProps) {
  return (
    <>
      <Link style={{ textDecoration: 'none', marginBottom: '1em' }} to="/edit-site">
        <Button variant="contained">New Site</Button>
      </Link>
      <SitesList />
    </>
  );
}