import { AxiosInstance } from 'axios';
import { refreshToken } from './refreshToken';
import { getRefreshToken, getToken } from './token';


const interceptors: { [key: string]: any } = {

};


export function setAxiosInterceptors(axiosInstance: AxiosInstance, onLogout: () => any = () => {}) {
  setAuthorizationHeaderInterceptor(axiosInstance);
  setUnauthorizedRequestInterceptor(axiosInstance, onLogout);
  setRefreshTokenInterceptor(axiosInstance);
}

export function setAuthorizationHeaderInterceptor(axiosInstance: AxiosInstance) {
  const interceptorName = 'authHeader';

  if(interceptors[interceptorName]) {
    axiosInstance.interceptors.request.eject(interceptors[interceptorName]);
  }

  interceptors[interceptorName] = axiosInstance.interceptors.request.use((config: any) => {
    const token = getToken() || '';

		if(token) {
    	config.headers.Authorization = `Bearer ${token}`;
		}
		
    return config;
  });
}

export function setUnauthorizedRequestInterceptor(axiosInstance: AxiosInstance, onLogout: () => any = () => {}) {
  const interceptorName = 'unauthRequest';

  if(interceptors[interceptorName]) {
    axiosInstance.interceptors.response.eject(interceptors[interceptorName]);
  }

  interceptors[interceptorName] = axiosInstance.interceptors.response.use(function (response) {
		return response;
	}, function (error) {
		if (401 === error.response.status && getToken() && !getRefreshToken()) {
			onLogout();
      console.log("Should logout here");
		} else {
			return Promise.reject(error);
		}
	});
}

let isRefreshing = false;
let failedQueue: any[] = [];

export function setRefreshTokenInterceptor(axiosInstance: AxiosInstance, onLogout: () => any = () => {}) {
  const interceptorName = 'refreshToken';

	if(interceptors[interceptorName]) {
		axiosInstance.interceptors.response.eject(interceptors[interceptorName]);
	}

	interceptors[interceptorName] = axiosInstance.interceptors.response.use(function (response) {
		return response;
	}, async function (error) {
		const originalConfig = error.config;

		if(401 === error.response.status && getRefreshToken() && !originalConfig._retry) {
			if(isRefreshing) {
				return new Promise(function(resolve, reject) {
					failedQueue.push({ resolve, reject });
				})
				.then(() => {
					return axiosInstance(originalConfig);
				})
				.catch(error => {
					return Promise.reject(error);
				})
			}

			originalConfig._retry = true;
			isRefreshing = true;

			if(await refreshToken(axiosInstance)) {
				processQueue();
				isRefreshing = false;

				return axiosInstance(originalConfig);
			} else {
				processQueue(error);
				onLogout();

				return Promise.reject(error);
			}
		}		
		
		return Promise.reject(error)
	});
}

function processQueue(error?: any) {
	failedQueue.forEach(promise => {
		if (error) {
			promise.reject(error);
		} else {
			promise.resolve();
		}
	});

	failedQueue = [];
};