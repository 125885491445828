/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, CircularProgress, Box, Snackbar, Alert, FormControlLabel, Switch } from "@mui/material";

import { useAppContext } from "../core/AppContext";
import * as Yup from "yup";
import { useSiteService } from "../sites/siteService";
import { useTenantService } from "../tenants/tenantService";
import MultiselectOption from "../shared/MulitselectOption";
import { AppName, AppNames, AppRoleValues } from "../core/AppConfig";
import { initUserProfileService, UserProfile } from "../security/userProfileService";

type Props = {
  profile: UserProfile | null;
};

const permissions = [
  "SprinklerValve.Report.Read",
  "PressureSignals.Read",
  "Entity.Read",
  "Signals.Read"
]

export const EditProfileForm: FC<Props> = ({ profile }) => {
  const app = useAppContext();
  const profileService = initUserProfileService();
  const siteService = useSiteService();
  const tenantService = useTenantService();

  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  
  const [tenants, setTenants] = useState<any[]>([]);
  const [sites, setSites] = useState<any[]>([]);
  const [siteOptions, setSiteOptions] = useState<any[]>([]);

  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [selectedSites, setSelectedSites] = useState<any[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<any[]>([]);
  const [fullAccessTenants, setFullAccessTenants] = useState<any[]>([]);
  const [selectedApplications, setSelectedApplications] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  useEffect(() => {
    tenantService.getAll().then(setTenants);
    siteService.getAll().then(sites => {
      setSites(sites);
      setSiteOptions(sites);
    });
  }, [app.user]);

  useEffect(() => {
    if (profile) {
      setIsPublic(profile?.isPublic ?? false);
      setFullAccessTenants(profile?.fullAccessTenantIds ?? []);
      setSelectedTenants(profile?.tenantIds ?? []);
      setSelectedSites(profile?.siteIds ?? []);
      setSelectedPermissions(profile?.permissions ?? []);
      setSelectedApplications(profile?.roles.map(role => {
        return (Object.keys(AppRoleValues) as AppName[]).find((appName: AppName) => AppRoleValues[appName] === role) ?? '';
      }) ?? []);
    }
  }, [profile]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Display Name is required"),
  });
  const formOptions = {
    defaultValues: {
      name: profile?.name ?? "",
    },
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  async function onSubmit({ name }: any) {
    if (!profile) {
      await profileService.addUserProfile({
        name,
        siteIds: selectedSites,
        tenantIds: selectedTenants,
        fullAccessTenantIds: fullAccessTenants,
        isPublic,
        permissions: selectedPermissions,
        roles: selectedApplications.map((appName: AppName) => AppRoleValues[appName]),
      });
    } else {
      await profileService.updateUserProfile({
        id: profile?.id,
        name,
        siteIds: selectedSites,
        tenantIds: selectedTenants,
        fullAccessTenantIds: fullAccessTenants,
        isPublic,
        permissions: selectedPermissions,
        roles: selectedApplications.map((appName: AppName) => AppRoleValues[appName]),
      });
    }
    setSuccessOpen(true);
  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: "1em" },
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <TextField
          label="Display Name"
          variant="outlined"
          fullWidth
          type="text"
          {...register("name")}
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.name?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Applications"
          values={selectedApplications}
          onChange={setSelectedApplications}
          items={AppNames.map((app) => ({ label: app, value: app }))}
          selectAllLabel={`All Applications`}
          renderSelectedValues={(values: any) =>
            values.length === AppNames.length
              ? `All Applications`
              : values
                  .map((value: any) => value)
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.applications?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Full Access of Tenants"
          values={fullAccessTenants}
          onChange={setFullAccessTenants}
          items={tenants.map((tenant) => ({ label: tenant.name, value: tenant.id }))}
          selectAllLabel={`All Tenants`}
          renderSelectedValues={(values: any) =>
            values.length === tenants.length
              ? `All Tenants`
              : values
                  .map(
                    (value: any) =>
                      tenants[tenants.findIndex((tenant) => tenant.id === value)]
                        ?.name
                  )
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.tenants?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Tenants"
          values={selectedTenants}
          onChange={setSelectedTenants}
          items={tenants.map((tenant) => ({ label: tenant.name, value: tenant.id }))}
          selectAllLabel={`All Tenants`}
          renderSelectedValues={(values: any) =>
            values.length === tenants.length
              ? `All Tenants`
              : values
                  .map(
                    (value: any) =>
                      tenants[tenants.findIndex((tenant) => tenant.id === value)]
                        ?.name
                  )
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.tenants?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Sites"
          values={selectedSites}
          onChange={setSelectedSites}
          items={siteOptions.map((site) => ({ label: site.name, value: site.id }))}
          selectAllLabel={`All Sites`}
          renderSelectedValues={(values: any) =>
            values.length === siteOptions.length
              ? `All Sites`
              : values
                  .map(
                    (value: any) =>
                      sites[sites.findIndex((site) => site.id === value)]?.name
                  )
                  .join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.sites?.message}
        </div>
      </div>
      <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <FormControlLabel
          control={
            <Switch
              checked={isPublic}
              onChange={(_, v) => setIsPublic(v)}
              color="primary"
            />
          }
          label={"Is Public Profile"}
        />
        <div style={{ color: 'grey' }}>
          <i>Public profiles are used for generating publicly accessible URLs.</i>
        </div>
      </div>
      {isPublic && <div style={{ paddingLeft: "1em", marginBottom: "1em" }}>
        <MultiselectOption
          variant="outlined"
          fullWidth
          label="Permissions"
          values={selectedPermissions}
          onChange={setSelectedPermissions}
          items={permissions.map((permission) => ({ label: permission, value: permission }))}
          selectAllLabel={`All Permissions`}
          renderSelectedValues={(values: any) =>
            values.length === siteOptions.length
              ? `All Permissions`
              : values.join(", ")
          }
        />
        <div style={{ color: "red", fontSize: "0.8em", paddingLeft: "1em" }}>
          {errors.sites?.message}
        </div>
      </div>}
      <button disabled={isSubmitting} className="btn btn-primary" type="submit">
        {isSubmitting ? <CircularProgress style={{ color: "#fff" }} /> : "Save"}
      </button>
      {errors.apiError && <div>{errors.apiError?.message}</div>}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          Profile successfully {!profile ? 'created' : 'updated'}!
        </Alert>
      </Snackbar>
    </Box>
  );
};
