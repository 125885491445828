import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTenantService } from "./tenantService";
import { EditTenantForm } from './EditTenantForm';

export default function EditTenantPage(props: RouteComponentProps) {
  const params = props.match.params as any;
  
  const tenantService = useTenantService();

  const [tenant, setTenant] = useState<any>(null);

  useEffect(() => {
    params.tenantId && tenantService.getOne(params.tenantId).then(setTenant);
  }, [params.tenantId]);
  
  return (
    <>
      {(!params.tenantId || !!tenant) && <EditTenantForm tenant={tenant} />}
    </>
  );
}