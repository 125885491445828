import { Paper, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { UserProfile } from '../security/userProfileService';

type Props = {
  profile: UserProfile;
}

export const ProfileCard: React.FC<Props> = ({ profile }) => {
  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{profile.name}</Typography>
        </div>
        <div>
          <Link to={`/edit-profile/${profile.id}`}>
            <EditIcon />
          </Link>
        </div>
      </div>
    </Paper>
  );
}