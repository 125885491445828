import { SECURITY_API_URL } from "../environment";
import { initAxiosFor } from "./axiosUtils";

export type AddAppRoleAssignment = {
  principalId: string;
  principalType?: string;
  resourceId: string;
  appRoleId: string;
}

export type AppRoleAssignment = AddAppRoleAssignment & {
  id: string;
}

export function initGraphApiService() {
  const axiosInstance = initAxiosFor(SECURITY_API_URL);

  const getUsers = async (): Promise<any> => {
    return (await axiosInstance?.get(`/api/graphapi/users`))?.data;
  }

  const getUser = async (id: string): Promise<any> => {
    return (await axiosInstance?.get(`/api/graphapi/users/${id}`))?.data;
  }

  const getUserRoleAssignments = async (id: string): Promise<any> => {
    return (await axiosInstance?.get(`/api/graphapi/users/${id}/appRoleAssignments`))?.data;
  }

  const addUser = async (user: any, userProfileId: string|null = null): Promise<any> => {
    return (await axiosInstance?.post(`/api/graphapi/users`, user, {
      params: {
        ...(userProfileId ? {userProfileId} : {})
      }
    }))?.data;
  }

  const updateUser = async (id: string, user: any, userProfileId: string|null = null): Promise<any> => {
    return (await axiosInstance?.put(`/api/graphapi/users/${id}`, user, {
      params: {
        ...(userProfileId ? {userProfileId} : {})
      }
    }))?.data;
  }

  const addAppRoleAssignment = async (userId: string, appRoleAssignment: AddAppRoleAssignment): Promise<AppRoleAssignment> => {
    return (await axiosInstance?.post(`/api/graphapi/users/${userId}/appRoleAssignments`, appRoleAssignment))?.data;
  }

  const removeAppRoleAssignment = async (userId: string, appRoleAssignmentId: string): Promise<{}> => {
    return (await axiosInstance?.delete(`/api/graphapi/users/${userId}/appRoleAssignments/${appRoleAssignmentId}`))?.data;
  }

  return {
    addUser,
    updateUser,
    addAppRoleAssignment,
    removeAppRoleAssignment,
    getUsers,
    getUser,
    getUserRoleAssignments,
  };
}