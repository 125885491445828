import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EditProfileForm } from './EditProfileForm';
import { initUserProfileService, UserProfile } from "../security/userProfileService";

export default function EditProfilePage(props: RouteComponentProps) {
  const params = props.match.params as any;

  const [profile, setProfile] = useState<UserProfile|null>(null);

  useEffect(() => {
    const profileService = initUserProfileService();
    if(params.profileId) {
      profileService.getUserProfileById(params.profileId)?.then(setProfile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.profileId]);
  
  return (
    <>
      {(!params.profileId || !!profile) && <EditProfileForm profile={profile} />}
    </>
  );
}