function index(obj: any, i: any) {return obj[i]}

const registry: { [key: string]: any } = {
  and: "and",
  on: "on",
  editSchedule: {
    add: "Add schedule",
    the_checklist: "The routine",
    starts_on: "starts on",
    should_become: "should become",
    it_becomes: "it becomes",
    before: "before",
    after: "after",
    visible: "visible",
    available: "available",
    for_doing: "for doing",
    overdue: "overdue",
    hidden: "hidden",
    upcoming_times: "Upcoming times",
    upcoming_times_invalid: "Invalid time interval",
    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    starting_at: "starting at",
    starting_now: "starting now",
    between: "between",
    selectedHalfHours: "every half hour",
    selectedHours: "selected hours",
    starting_at_simple: "at",
    every: "every",
    every_day_of_the_week: "every day of the week",
    advanced: "Advanced",
  },
  daysInWeek: {
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
}

export function useTranslation() {
  return {
    t: (key: string) => key.split('.').reduce(index, registry) ?? 'not existing key',
  }
}