import { FC } from "react";
import { getNextDatesFromCronStatement } from "./util";
import { useTranslation } from "./translation";

export const UpcomingTimes: FC<{cronStatement: string}> = ({ cronStatement }) => {
  const { t } = useTranslation();
  
  const nextDates = getNextDatesFromCronStatement(cronStatement);

  return nextDates?.length ? (
    <>
      <br/>
      <br />
      {t('editSchedule.upcoming_times')}: 
      <span> </span>
      {nextDates.map(item => item.format('lll')).join(', ')}...
    </>
  ) : <p style={{color: 'red'}}>{t('editSchedule.upcoming_times_invalid')}</p>;
}